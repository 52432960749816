.container{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.avatar {
    width: 128px;
    height:128px;
    object-fit:cover
}