.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 26px;
    color: #40a9ff;
}

.input{
    font-size: 24px;
    color: #4f4f4f;
}

.section{
    margin-top: 20px;
}