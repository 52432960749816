.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.title-text{
    padding:10px;
    font-size: 140%;
    font-weight: bolder;
}