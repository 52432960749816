.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}

.qrcode-pic{
    width: 100%;
    padding:30px;
}