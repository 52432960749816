.server-select-title {
  padding: 20px;
  text-align: center;
  font-size: 24px;
}

.server-select-label {
  margin-left: 200px;
}

.server-selection {
  /* margin-left: 20px; */
  min-width: 250px;
}
