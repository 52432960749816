.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.section{
    padding:10px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 20px;
    color: #40a9ff;
}