.page-container{
    padding:20px;
}

.date-button-group .ant-calendar-picker-input{
    border-bottom-left-radius:  0 !important;
    border-top-left-radius:  0 !important;
}

.selectedPeriodButton{
    color: #2979ff;
    border-color: #2979ffaa;
    z-index: 100;
}