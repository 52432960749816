body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo{
  margin:20px;
  height:80px;
  background-color: #282c34;
  text-align: center;
  vertical-align: center;
  transition: all ease-out 1s;
  border-radius: 5px;
}

.logo-small{
  margin:20px;
  height:40px;
  background-color: #282c34;
  text-align: center;
  vertical-align: center;
  transition: all ease-out 1s;
  border-radius: 2px;


}

.logo-image{
  height:50px;
  margin-left:auto;
  margin-right:auto;
  margin-top: 15px;
  transition: all ease-out 1s;


}
.logo-image-small{
  height:30px;
  margin-left:auto;
  margin-right:auto;
  margin-top: 5px;
  transition: all ease-out 1s;

}
.server-select-title {
  padding: 20px;
  text-align: center;
  font-size: 24px;
}

.server-select-label {
  margin-left: 200px;
}

.server-selection {
  /* margin-left: 20px; */
  min-width: 250px;
}

.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.section{
    padding:10px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 20px;
    color: #40a9ff;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 26px;
    color: #40a9ff;
}

.input{
    font-size: 24px;
    color: #4f4f4f;
}

.section{
    margin-top: 20px;
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.section{
    padding:10px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 20px;
    color: #40a9ff;
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.section{
    padding:10px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 20px;
    color: #40a9ff;
}
.login-form-button{
    width: 100%;
}
.upload-button-wrapper > span{
    display: block!important;
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.title-text{
    padding:10px;
    font-size: 140%;
    font-weight: bolder;
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.section{
    padding:10px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e8e8;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.title-text{
    font-size: 26px;
}
.deposit-value{
    font-size: 20px;
    color: #40a9ff;
}
.page-container{
    padding:20px;
}

.date-button-group .ant-calendar-picker-input{
    border-bottom-left-radius:  0 !important;
    border-top-left-radius:  0 !important;
}

.selectedPeriodButton{
    color: #2979ff;
    border-color: #2979ffaa;
    z-index: 100;
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.contianer{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}

.qrcode-pic{
    width: 100%;
    padding:30px;
}
.CreateUserSection{
    padding:20px;
}

.selectFormItem{
    width: 50%;
}
.avatar-uploader {
    margin-left: 42%;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 98px;
}

.CreateOrganizationSection {
    padding: 20px;
}

.ant-form-item{
 margin-bottom: 14px;
 /* column-gap: 2px; */
}
.container{
    margin:20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding:20px
}
.avatar {
    width: 128px;
    height:128px;
    object-fit:cover
}
.uploadIcon ant-upload ant-upload-btn{
    padding: 0 0 0 0;
}


